import React from 'react';
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import Button from '@mui/material/Button/Button';
import Divider from '@mui/material/Divider/Divider';
import Grid from '@mui/material/Grid/Grid';
import DescriptionCta from 'components/molecules/DescriptionCta/DescriptionCta';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';
import Section from 'components/organisms/Section/Section';
import CardBase from 'components/organisms/CardBase/CardBase';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    '& .description-cta__button-group': {
      flexWrap: 'nowrap',
    },
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  divider: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(5, 0),
    },
  },
  textWhite: {
    color: 'white',
  },
  cardHighlighted: {
    background: theme.palette.primary.dark,
  },
  checkBox: {
    background: 'transparent',
    borderRadius: 0,
    width: 30,
    height: 30,
  },
  list: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  link: {
    color: theme.palette.primary.main,
  },
}));

const CompanyCookies = () => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  return (
    <div className={classes.root}>
      <Section className={classes.pagePaddingTop}>
        <DescriptionCta
          title="Cookies Policy"
          subtitle="Last updated: (10/08/2020)"
          primaryCta={
            <Button variant="outlined" color="primary" size="large">
              Print
            </Button>
          }
          align={'left'}
          titleProps={{
            className: classes.fontWeightBold,
            color: 'textPrimary',
          }}
          subtitleProps={{
            color: 'textSecondary',
          }}
        />
        <Divider className={classes.divider} />
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12} md={8}>
            <p>
              VRGeoscience Limited ("us", "we", or "our") uses cookies on
              www.vrgeoscience.com (the "Service"). By using the Service, you
              consent to the use of cookies.
            </p>
            <p>
              Our Cookies Policy explains what cookies are, how we use cookies,
              how third-parties we may partner with may use cookies on the
              Service, your choices regarding cookies and further information
              about cookies.
            </p>
            <h2>What are cookies</h2>
            <p>
              Cookies are small pieces of text sent by your web browser by a
              website you visit. A cookie file is stored in your web browser and
              allows the Service or a third-party to recognize you and make your
              next visit easier and the Service more useful to you.
            </p>
            <p>Cookies can be "persistent" or "session" cookies.</p>
            <h1> How VRGeoscience Limited uses cookies</h1>
            <p>
              When you use and access the Service, we may place a number of
              cookies files in your web browser.
            </p>
            <p>
              We use cookies for the following purposes: to enable certain
              functions of the Service, to provide analytics, to store your
              preferences, to enable advertisements delivery, including
              behavioural advertising.
            </p>
            <p>
              We use both session and persistent cookies on the Service and we
              use different types of cookies to run the Service:
            </p>
            <li>
              Essential cookies. We may use essential cookies to authenticate
              users and prevent fraudulent use of user accounts.
            </li>
            <li>
              Third-party cookies. In addition to our own cookies, we may also
              use various third-parties cookies to report usage statistics of
              the Service, deliver advertisements on and through the Service,
              and so on.
            </li>
            <h2>What are your choices regarding cookies</h2>
            <p>
              If you'd like to delete cookies or instruct your web browser to
              delete or refuse cookies, please visit the help pages of your web
              browser. Please note, however, that if you delete cookies or
              refuse to accept them, you might not be able to use all of the
              features we offer, you may not be able to store your preferences,
              and some of our pages might not display properly.
            </p>
            <h2>Where can your find more information about cookies</h2>
            <p>
              You can learn more about cookies and the following third-party
              websites:
            </p>
            <li> AllAboutCookies: http://www.allaboutcookies.org/</li>
            <li>
              Network Advertising Initiative: http://www.networkadvertising.org/
            </li>
          </Grid>

          <Grid item xs={12} md={4}>
            <Grid container spacing={isMd ? 4 : 2} direction="column">
              <Grid item xs={12}>
                <CardBase withShadow className={classes.cardHighlighted}>
                  <SectionHeader
                    title="Have a question?"
                    subtitle="Not sure exactly what we’re looking for or just want clarification? We’d be happy to chat with you and clear things up for you. Anytime!"
                    ctaGroup={[
                      <Button variant="contained" href="/contact-us">
                        Contact us
                      </Button>,
                    ]}
                    disableGutter
                    align="left"
                    titleProps={{
                      variant: 'subtitle1',
                      className: clsx(
                        classes.textWhite,
                        classes.fontWeightBold,
                      ),
                    }}
                    subtitleProps={{
                      variant: 'body2',
                      className: classes.textWhite,
                    }}
                  />
                </CardBase>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Section>
      <Divider />
    </div>
  );
};

export default CompanyCookies;
